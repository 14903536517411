import React from "react"
import Container from "../components/container"
import { Link, graphql } from "gatsby"

export default ({ data }) => {
  console.log(data)
  return (
    <Container whyDevNano={data.whyDevNano} author={data.author}>
      {data.blog.edges.map(({ node }) => (
        <div key={node.id}>
          <Link to={node.fields.slug}>
            <h3>{node.frontmatter.title}</h3>
          </Link>
          <p>{node.excerpt}</p>
        </div>
      ))}
    </Container>
  )
}

export const query = graphql`
  query($langKey: String) {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        fields: { langKey: { eq: $langKey } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    ...HeaderFragment
  }
`
